<template>
  <layout>
    <div class="search-top">
      <div class="contant">
        <div class="contant-top">
          <div class="contant-logo">
            <img src="@/assets/home/logo.png" alt @click="$router.push('/')"/>
            <span>爱玩就上爱租号</span>
          </div>
          <div class="contant-search">
            <input type="text" placeholder="搜你想要的~" v-model="valueList"/>
            <button @click="goSerach">搜索</button>
          </div>
        </div>
        <div class="contant-bottom">
          <div class="contant-hot">
            <img src="@/assets/home/hot.png" alt />
            <span>热门游戏</span>
              <div class="newClass">
              <hotlist></hotlist>
            </div>
          </div>
          <ul>
            <li @click="toClick(0)">首页</li>
            <li :class="{ activel: isShows == 1 }" @click="toClick(1)">
              爱租号
            </li>
            <li @click="toClick(2)">
              工具下载
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="game_box">
      <div class="game_content w">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>租号大厅</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="game_head">
          <h5>租号大厅</h5>
          <div class="log_list">
            <span>热门端游：</span>
            <ul class="clearfix lib">
              <li v-for="item in pcList" :key="item.game_id">
                <img :src="item.icon" alt />
                <div class="xstc" @click="refresh(item.game_id)">
                  {{ item.game_name }}
                </div>
              </li>
            </ul>
          </div>
          <div class="log_list">
            <span>热门手游：</span>
            <ul class="clearfix lib">
              <li v-for="item in mobileList" :key="item.game_id">
                <img :src="item.icon" alt />
                <div class="xstc" @click="refresh(item.game_id)">
                  {{ item.game_name }}
                </div>
              </li>
            </ul>
          </div>
          <div class="log_list sx">
            <span class="qb">全部游戏：</span>
            <div class="search_lf clearfix">
              <div class="bkx" @click="gameLists(1)">
                <span>{{ gameinner }}</span>
                <img src="@/assets/home/xl-ss.png" alt />
              </div>
              <div @click="gameLists(2)">
                <span>{{gameinner1}}</span>
                <img src="@/assets/home/xl-ss.png" alt />
              </div>
              <div @click="gameLists(3)">
                <span>{{gameinner2}}</span>
                <img src="@/assets/home/xl-ss.png" alt />
              </div>
            </div>
            <!-- 级联选择器1 -->
            <div class="inputcard" v-show="isgame">
              <div class="card_letter">
                <ul class="clearfix">
                  <li
                    v-for="(item,index) in list"
                    :key="index"
                    :class="{aat:letter == index}"
                    @click="chooselist(item,index)"
                  >{{item}}</li>
                </ul>
              </div>
              <div class="game_list">
                <ul class="clearfix">
                  <li v-for="(item, index) in gameList" :key="index" @click="oneClick($event,item.game_id)">{{item.game_name}}</li>
                </ul>
              </div>
              <div class="unknown">找不到好玩的游戏?</div>
            </div>
            <!-- 2级选择器 -->
            <div class="inputcard" v-show="iscard">
              <div class="game_list">
                <ul class="clearfix sxl">
                  <li @click="oneClick1($event)">全区全服</li>
                  <li v-for="(item,index) in gamearea" :key="index" @click="oneClick1($event,item.id)">{{item.game_name}}</li>
                </ul>
              </div>
              <div class="unknown">找不到好玩的游戏?</div>
            </div>
            <!-- 3级选择器 -->
            <div class="inputcard" v-show="iscard2">
              <div class="game_list ">
                <ul class="clearfix sxl">
                  <li @click="oneClick2($event)">全区全服</li>
                  <li v-for="(item,index) in gamearea1" :key="index" @click="oneClick2($event)">{{item.game_name}}</li>
                </ul>
              </div>
              <div class="unknown">找不到好玩的游戏?</div>
            </div>
          </div>
        </div>
        <div class="filtrate">
          <ul>
            <li :class="{ att: gametype == 0 }" @click="tapclick(0)">
              综合排序
            </li>
            <li :class="{ att: gametype == 1 }" @click="tapclick(1)">
              时间
              <i :class="time_order == 1?'el-icon-top':'el-icon-bottom'"></i>
            </li>
            <!-- <li :class="{att: gametype == 2}" @click="tapclick(2)">
              热度
            </li> -->
            <li :class="{ att: gametype == 3 }" @click="tapclick(3)">
              价格
             <i :class="price_order == 1?'el-icon-top':'el-icon-bottom'"></i>
            </li>
          </ul>
        </div>
        <div class="good_box clearfix">
          <div class="good_left">
            <ul>
              <li v-for="(item, index) in infoList" :key="index">
                <div class="lf_img"  @click.stop="openPath(item)">
                  <img :src="item.imageurl || item.game_img" alt />
                  <div class="bgs">
                    {{ item.big_game_name ||item.game_name}}
                    <img v-show="false" src="@/assets/lobby/qq.png" alt="" />
                    <img v-show="false" src="@/assets/lobby/wx.png" alt="" />
                  </div>
                </div>
                <div class="nr">
                  <div class="lf_xy">
                    <h4  @click.stop="openPath(item)">{{ item.goods_title || item.youxi}}</h4>
                    <p class="p1">{{ item.game_all_name ||`${item.game_name}-${item.game_server_name}-${item.game_zone_name}`}}</p>
                    <div>
                      <i v-if="item.categoryid == 2">英雄{{item.yx}}</i>
                      <i v-else>英雄45</i>
                     
                      <i v-if="item.pf">皮肤{{item.pf}}</i>
                      <i v-else>皮肤28</i>
                    </div>
                    <div>
                      <span>{{ item.actity }}</span>
                    </div>
                  </div>
                  <div class="lf_zj">
                    <div class="zpjg">{{ item.lease_price ||item.pmoney }}元/小时</div>
                    <div class="vip" v-if="false">
                      <img src="@/assets/lobby/vip.png" alt="" />
                      <span>3.38元/小时</span>
                    </div>
                    <div class="yj">
                      押金:{{ item.bzmoney ? item.bzmoney : item.foregift }}元|{{ item.short_lease ||item.szq }}小时起租
                    </div>
                  </div>
                  <div class="lf_hj">
                    <p>热度{{ item.hot_value }}</p>
                    <div @click.stop="openPath(item)">立即租用</div>
                  </div>
                </div>
              </li>
            </ul>
            <!-- 分页器 -->
            <div class="paging">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-size="8"
                layout="prev, pager, next,total, jumper"
                :total="total"
                background
              ></el-pagination>
            </div>
          </div>
          <div class="good_right">
            <h4>热租账号</h4>
            <ul>
              <li
                v-for="item in hotAccount"
                :key="item.goods_id"
                @click="toPath(item.goods_id)"
              >
                <img :src="item.imageurl" alt />
                <p>{{ item.goods_title }}</p>
                <div>
                  <span>¥{{ item.lease_price }}</span>
                  <span>热度{{ item.hot_value }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <side-Bar></side-Bar>
  </layout>
</template>

<script>
import layout from "../../components/layout";
import sideBar from "../../components/common/sidebar";
import hotlist from '../../components/hotlist'

import qs from 'qs' 

export default {
  components: {
    layout,
    sideBar,
    hotlist
  },
  data() {
    return {
      // 热租账号
      hotAccount: [],
      // 手游hotAccount
      mobileList: [],
      // 端游
      pcList: [],
      //游戏列表
      infoList: [],
      pricetype: true,
      price_order: null, //价格筛选
      timetype: true,
      time_order: null, //时间筛选
      total: null, //数据总条数
      page: 1,//底部分页
      page1:1,//游戏搜索框必传page
      game_id: null,
      key_word:"",
      valueList:"",
      pid:"",
      is_hot:1,
      area:"",
      server:"",
      first_letter: "",
      gameList:[],
      gamearea:[],
      gamearea1:[],
      page_size: 10,
      gamenum: null,
      isgame: false,
      iscard:false,
      iscard2:false,
      isShows: 1,
      gametype: 0,
      currentPage: 1, //分页器当前页
      letter: 0, //联级选择器
      gameinner: "游戏",
      gameinner1:"选择大区",
      gameinner2:"选择服务器",
      list: [
        "热门",
        "A",
        "B",
        "C",
        "D",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
    };
  },
  created() {
    this.mactGame();
    this.getHotList();
    this.getHotGame(2);
    this.getHotGame(1);
    this.getInfoList();
    this.getMyinfo();
    console.log(this.$route.query,'123');
    // this.searchValue()
    if(this.$route.query.key_word != null && 'undefined'){
      this.valueList = this.$route.query.key_word
    }
  },
  mounted() {},
  methods: {
    toClick(e) {
      if (e == 0) {
        this.$router.push({
          path: "/home",
        });
      } else if (e == 2) {
        this.$router.push({
          path: "/download",
        });
      } else if (e == 1) {
        this.game_id = null;
        this.getInfoList();
        
      }
      this.isShows = e;
    },
    mactGame() {
    
      this.game_id = this.$route.query.game_id;
      this.server = this.$route.query.server;
      this.area = this.$route.query.area;
      this.key_word = this.$route.query.key_word
      console.log("111");

    },
    gameLists(e) {
       if(e ==1){
        this.isgame = !this.isgame;
        this.iscard =false
        this.iscard2 =false
      }else if(e==2){
        if(this.gamearea.length == 0){
          this.isgame =false
        }else{
        this.iscard = !this.iscard
        this.isgame =false
        this.iscard2 =false
        }
      }else if(e == 3){
         if(this.gamearea1.length == 0){
          this.isgame =false
        }else{
        this.iscard2 = !this.iscard
        this.isgame =false
        this.iscard =false
        }
      }
    },
    tapclick(e) {
      this.gametype = e;
      console.log(this.gametype);
      if (this.gametype == 3) {
        if (this.pricetype) {
          this.price_order = 1;
          this.getInfoList();
          this.pricetype = false;
        } else {
          this.price_order = 0;
          this.getInfoList();
          this.pricetype = true;
        }
      } else if (this.gametype == 1) {
        if (this.timetype) {
          this.time_order = 1;
          this.price_order = -1;
          this.getInfoList();
          this.timetype = false;
          console.log(this.timetype);
        } else {
          this.time_order = 0;
          this.price_order = -1;
          this.getInfoList();
          this.timetype = true;
          console.log(this.timetype);
        }
      }else{
        this.time_order = null;
        this.price_order = null;
         this.getInfoList();
      }
    },
    //联级选择器样式
    chooselist(item, index) {
      this.letter = index;
      if(index == 0){
        this.getMyinfo();
      }else{
        this.is_hot =""
        this.first_letter = this.list[index]
        this.getMyinfo();
      }
    },
    oneClick(e,id) {
      console.log(e.target.innerText);
      this.gameinner = e.target.innerText;
      this.isgame = false;
      this.iscard =true;
      this.game_id = id
      this.getFiltrateList() 
    },
    oneClick1(e,pid) {
      this.gameinner1 = e.target.innerText;
      if(e.target.innerText == '全区全服'){
        this.area1=''
      }else{
         this.area =e.target.innerText;
      }
      this.iscard = false;
      this.iscard2 = true;
      this.pid = pid
      this.getGameArea()
    },
    oneClick2(e) {
      this.key_word =''
      this.gameinner2 = e.target.innerText;
      
      console.log(this.key_word);
      if(e.target.innerText == '全区全服'){
        this.server =''
      }else{
      this.server = e.target.innerText
      }
      this.iscard2 = false;
      this.getInfoList()
    },
    //分页器设置
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getInfoList();
    },
    refresh(index) {
       this.$router.push({
        path:'/lobby',
        query:{
         game_id : index,
        }
      })
      this.currentPage = 1
      this.page = 1
      this.game_id = index;
      this.area =''
      this.server=''
      this.key_word=''
      this.getInfoList();
    },
    // 获取数据
    getHotList() {
      let user_id = this.$store.state.user_id;
      this.$get("api/index", { user_id }).then((res) => {
        if (res.code == 0) {
          // 热租账号
          this.hotAccount = res.ret.fire_game.slice(0, 5);
        }
      });
    },
    // 热门手游,端游
    getHotGame(type) {
      this.$get("api/hot_game", { game_type: type }).then((res) => {
        if (res.code == 0) {
          if (type == 2) {
            // 手游
            this.mobileList = res.ret.hot_game.slice(0, 8);
          } else {
            this.pcList = res.ret.hot_game.slice(0, 8);
          }
        }
      });
    },
    //游戏列表
    getInfoList() {
       let key_word = this.$route.params.keyword
       this.$get("api/account_list", {
        game_id: this.game_id,
        server:this.server,
        area:this.area,
        key_word:this.key_word,
        page: this.page,
        page_size: this.page_size,
        price_order: this.price_order,
        time_order: this.time_order,
      }).then((res) => {
        if (res.code == 0&& res.ret.length != 0) {
            let newList = res.ret.result.list || []
            this.infoList = newList.concat(res.ret.result.list_zhw || [])
            this.total = res.ret.result.total;
        }
      });
    },
    //游戏筛选列表请求1
    getMyinfo() {
      let user_id = this.$store.state.user_id;
      this.$get("api/game_list", {
        user_id,
        game_id:this.game_id,
        page: this.page1,
        first_letter: this.first_letter,
        is_hot: this.is_hot,
      }).then((res) => {
        //console.log(res);
        this.gameList =res.ret
        console.log(res.ret);
      });
    },
    //联级选择器二级
    getFiltrateList() {
      this.$get("api/game_filtrate_list", {
        game_id: this.game_id,
      }).then((res) => {
        this.gamearea = res.ret.gamearea;
        console.log(this.gamearea);
      });
    },
    //联级选择器三级
    getGameArea() {
      this.$get("api/game_area", {
        pid: this.pid,
      }).then((res) => {
        this.gamearea1 = res.ret;
        console.log(this.gamearea1);
      });
    },
    // // 跳转方法
    // toPath(id) {
    //   this.$router.push({
    //     path: `/detail/${id}`,
    //   });
    // },
    // 租号玩数据
    openPath(item) {

     if(item.new_channel==1) {
        this.$router.push({
          path: `/detail/${item.goods_id}`,
        });
     }else{
        this.$router.push({
         path: `/detail/${qs.stringify(item)}`,
        });
       
     }
    },
    goSerach() {
      this.$router.push({
        path:'/lobby',
        query:{
         key_word : this.valueList
        }
      })
      this.key_word = this.valueList
      this.area =''
      this.server=''
      this.game_id =''
      this.getInfoList()
    },
  },
};
</script>

<style lang='scss' scoped>
.search-top {
  width: 100%;
  background-color: #fff;
  height: 168px;
  .contant {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    .contant-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 40px;
      height: 86px;
      .contant-logo {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 218px;
          height: 55px;
          margin-right: 18px;
        }
        span {
          font-size: 15px;
        }
      }
      .contant-search {
        width: 480px;
        height: 48px;
        background: #ffffff;
        border: 2px solid #3c7efe;
        border-right: none;
        border-radius: 24px;
        box-sizing: border-box;
        display: flex;

        input {
          width: 100%;
          height: 100%;
          border: none;
          outline-style: none;
          border-radius: 24px;
          text-indent: 2em;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #a2a6b8;
        }
        button {
          position: relative;
          width: 120px;
          height: 48px;
          background: #3c7efe;
          border-radius: 24px;
          color: #ffffff;
          font-size: 18px;
          box-sizing: border-box;
          margin-top: -2px;
          padding-left: 20px;
          cursor: pointer;
        }
        button::after {
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          background: url(../../assets/home/ss.png) no-repeat center center;
          background-size: 100% 100%;
        }
      }
    }
    .contant-bottom {
      display: flex;
      .contant-hot {
        width: 200px;
        height: 42px;
        border-radius: 12px 12px 0 0;
        background-color: #3c7efe;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
         position: relative;
          cursor: pointer;
        &:hover .newClass{
          max-height: 460px;
          transition: max-height ease-in .3s;
        }
        img {
          width: 16px;
          height: 18px;
          margin-right: 12px;
        }
        span {
          font-size: 16px;
          color: #ffffff;
        }
        
        .newClass{
          position: absolute;
          top: 42px;
          left: 0;
          max-height: 0;
          min-height: 0;
          overflow: hidden;
          transition: max-height ease-out .4s;
          z-index: 9999;
        }
      }
      ul {
        display: flex;
        align-items: center;
        li {
          font-size: 14px;
          color: #666;
          padding: 0 30px;
          // height: 100%;
          line-height: 17px;
          position: relative;
          cursor: pointer;
        }
        .activel{
          color: #000;
          font-weight: 700;
        }
        .activel:after {
          position: absolute;
          bottom: -9px;
          left: 50%;
          margin-left: -7px;
          display: block;
          content: "";
          width: 14px;
          height: 3px;
          background-color: #3b7dfd;
        }
      }
    }
  }
}
.w {
  width: 1200px;
  margin: 0 auto;
}
.game_box {
  width: 100%;
  background-color: #f6f7fa;
  .game_content {
    .el-breadcrumb {
      padding: 20px 0;
    }
    .game_head {
      width: 100%;
      height: 320px;
      background-color: #fff;
      border-radius: 12px;
      h5 {
        padding: 15px 0 0 20px;
        font-size: 14px;
        font-weight: 700;
      }
      .log_list {
        display: flex;
        align-items: center;
        width: 100%;
        height: 60px;
        margin-top: 30px;
        margin-left: 18px;
        box-sizing: border-box;
        color: #83899d;
       
        .lib > li {
          position: relative;
          float: left;
          width: 100px;
          height: 56px;
          background-color: #fff;
          border: 1px solid #dadee4;
          margin-left: 24px;
          border-radius: 12px;
          padding: 2px;
          overflow: hidden;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            border-radius: 12px;
            object-fit: cover;
            object-position: center;
            // width: auto;
            // height: auto;
            // max-height: 100%;
            // max-width: 100%;
          }
          .xstc {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba($color: #3c7efe, $alpha: 0.8);
            text-align: center;
            color: #fff;
            line-height: 56px;
            display: none;
          }
        }
        .lib > li:hover .xstc {
          display: block;
        }
        .qb {
          margin-right: 24px;
        }
        .search_lf div {
          float: left;
          display: flex;
          justify-content: space-around;
          align-items: center;
          height: 32px;
          border: 1px solid #dadee4;
          border-radius: 10px;
          padding: 0 10px;
          box-sizing: border-box;
          margin-right: 15px;
          cursor: pointer;
          img {
            margin-left: 5px;
          }
        }
      }
      .sx {
        position: relative;
        .inputcard {
          position: absolute;
          left: 80px;
          top: 55px;
          width: 690px;
          // height: 440px;
          background-color: #fff;
          z-index: 333;
          border: 1px solid #3c7efe;
          border-radius: 12px;
          padding: 15px 25px;
          box-sizing: border-box;
          li {
            float: left;
          }
          .card_letter {
            ul > li {
              width: 16px;
              height: 16px;
              // opacity: 0.3;
              border-radius: 50%;
              font-size: 12px;
              text-align: center;
              margin-left: 5px;
            }
            li:hover {
              background: #c4d8ff;
              color: #3c7efe;
            }
            .aat {
              color: #fff;
              background: #3c7efe;
            }
            ul li:nth-child(1) {
              margin-right: 40px;
              width: 32px;
            }
          }
          .game_list {
            ul {
              margin-top: 30px;
              li {
                width: 105px;
                margin: 0 20px 20px 0;
              }
              li:hover {
                color: #3c7efe;
              }
            }
            .sxl {
              margin-top: 10px;
            }
          }
          .unknown {
            width: 100%;
            height: 40px;
            text-align: right;
          }
        }
      }
    }
    .filtrate {
      width: 100%;
      height: 50px;
      background-color: #fff;
      border-radius: 8px;
      margin: 20px 0 10px;
      ul {
        li {
          float: left;
          width: 104px;
          height: 50px;
          margin-right: 5px;
          border-radius: 8px;
          text-align: center;
          line-height: 50px;
          cursor: pointer;
          i{
            font-size: 13px;
            font-weight: bold;
          }
        }
        li:hover {
          background-color: #3c7efe;
          color: #fff;
        }
      }
      .att {
        background-color: #3c7efe;
        color: #fff;
        span {
          background: url(../../assets/lobby/ss-bai.png) no-repeat center center;
          background-size: 100% 100%;
        }
      }
    }
    .good_box {
      width: 100%;
      .good_left {
        float: left;
        width: 1000px;
        background: #fff;
        border-radius: 12px;
        ul {
          li {
            display: flex;
            width: 100%;
            height: 140px;
            padding: 20px;
            box-sizing: border-box;
           
            .lf_img {
              position: relative;
              width: 100px;
              height: 100%;
              overflow: hidden;
              border-radius: 12px;
               cursor: pointer;
              img {
                width: 100%;
                height: 100%;
              }
              .bgs {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.7);
                width: 100%;
                height: 100%;
                text-align: center;
                line-height: 100px;
                font-size: 14px;
                font-weight: 700;
                color: #fff;
                img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 22px;
                  height: 22px;
                }
              }
            }
            .nr {
              flex: 1;
              display: flex;
              justify-content: space-between;
              padding-left: 15px;
              box-sizing: border-box;
              .lf_xy {
                width: 400px;
                height: 100%;
                display: flex;
                flex-flow: column;
                justify-content: space-between;
                h4 {
                  font-size: 14px;
                  font-weight: 700;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .p1 {
                  color: #83899d;
                  font-size: 12px;
                }
                .p2 {
                  color: #a2a6b8;
                }
                i {
                  display: inline-block;
                  border-radius: 4px;
                  font-size: 12px;
                  text-align: center;
                  padding: 0px 3px;
                }
                i:nth-child(1) {
                  border: 1px solid #12ceb0;
                  color: #12ceb0;
                }
                i:nth-child(2) {
                  border: 1px solid #f49800;
                  color: #f49800;
                  margin-left: 12px;
                }
                div {
                  span {
                    display: inline-block;
                    margin-right: 5px;
                    font-size: 12px;
                    color: #f94f38;
                  }
                }
              }
              .lf_zj {
                display: flex;
                flex-flow: column;
                justify-content: space-around;
                align-items: center;
                width: 150px;
                height: 100%;
                padding: 10px 0;
                box-sizing: border-box;
                div {
                  font-size: 14px;
                }
                .zpjg {
                  color: #fc3737;
                  font-weight: 700;
                }
                .yj {
                  font-size: 12px;
                  color: #a1a6b7;
                }
                .vip {
                  font-weight: 700;
                  width: 100%;
                  height: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  img {
                    width: 32px;
                    height: 17px;
                    margin-right: 5px;
                  }
                }
              }
              .lf_hj {
                display: flex;
                flex-flow: column;
                justify-content: space-between;
                align-items: flex-end;
                align-items: center;
                // width: 150px;
                height: 100%;
                padding: 20px;
                h4 {
                  font-weight: 700;
                  color: #fc3838;
                  font-size: 14px;
                }
                p {
                  font-size: 12px;
                  color: #a1a6b7;
                }
                div {
                  width: 118px;
                  height: 36px;
                  border: 2px solid #3c7efe;
                  border-radius: 18px;
                  box-sizing: border-box;
                  color: #3c7efe;
                  font-weight: 700;
                  font-size: 14px;
                  text-align: center;
                  line-height: 32px;
                }
                div:hover {
                  background-color: #3c7efe;
                  box-shadow:0px 2px 12px #3c7efe;
                  color: #fff;
                  cursor: pointer;
                }
              }
            }
          }
          li:hover {
            background-color: #f6f7fa;
          }
          li:hover .bgs {
            display: block;
          }
        }
      }
      .good_right {
        float: right;
        width: 190px;
        border-radius: 12px;
        background: #fff;
        padding: 20px;
        box-sizing: border-box;
        h4 {
          font-size: 14px;
          font-weight: 700;
        }
        ul {
          li {
            width: 100%;
            margin-top: 20px;
            cursor: pointer;
            img {
              width: 100%;
              height: 150px;
              border-radius: 12px;
            }
            p {
              font-size: 12px;
              font-family: Microsoft YaHei;
              color: #848a9e;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
            div {
              display: flex;
              justify-content: space-between;
              padding-top: 5px;
              span:nth-child(1) {
                font-weight: 700;
                font-size: 12px;
                color: #fc3838;
              }
              span:nth-child(2) {
                font-size: 12px;
                color: #848a9e;
              }
            }
          }
        }
      }
    }
  }
}
//分页器设置
.paging {
  padding: 30px 10px;
}
</style>
<style >
.el-pagination.is-background .el-pager li:not(.disabled) {
  border-radius: 15px;
  margin: 0px 8px;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #3c7efe;
  color: #fff;
}
.el-pagination.is-background .el-pager li.active {
  color: #fff !important;
  cursor: default;
}
.el-pagination.is-background .el-pager li:hover {
  color: #3c7efe;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #3c7efe;
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  background-color: #3c7efe;
  color: #fff;
}
.el-pagination.is-background .el-pagination__total {
  margin-left: 295px;
}
</style>